import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faUser, faEnvelope, faPhoneAlt, faMobileAlt, faComment, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope as farEnvelope, faCalendarAlt, faClock, faCalendarTimes } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fab, faUser, faEnvelope, faMobileAlt, faComment, faMapMarkerAlt, farEnvelope, faCalendarAlt, faClock, faCalendarTimes)

const ContactUs = () => (
  <div>
    <div className='anchor' id='contact-us'></div>
    <section className="section" id="contact-us-content">
      <div className="container">
        <h3 className="title is-3 has-text-centered title-line">Get In Touch</h3>

        <div className="columns is-centered">
          <div className="column is-one-quarter">
            <h5 className="title is-5">Message Us</h5>
            <p id="message-link">
              <a href='https://wa.me/60123166889' aria-label='whatsapp'><FontAwesomeIcon icon={[`fab`, `whatsapp`]} /></a>
              <a href='https://wa.me/60125209862' aria-label='whatsapp'><FontAwesomeIcon icon={[`fab`, `whatsapp`]} /></a>
              <a href='https://m.me/Elegantclinic.com.my' aria-label='messenger'><FontAwesomeIcon icon={[`fab`, `facebook-messenger`]} /></a>
            </p>
          </div>

          <div className="column is-one-quarter">
            <h5 className="title is-5">Locate Us</h5>
            <div className="media-display">
              <div className="media-left">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </div>
              <div className="media-content">
                <p>A-G-5 Kuchai Exchange,</p>
                <p>No 43 jalan Kuchai Maju 13</p>
                <p>58200 Kuala Lumpur.</p>
              </div>
            </div>
            <div className="media-display">
              <div className="media-left">
                <FontAwesomeIcon icon={faMobileAlt} />
              </div>
              <div className="media-content">
                <p>+6012 316 6889</p>
              </div>
            </div>
            <div className="media-display">
              <div className="media-left">
                <FontAwesomeIcon icon={faMobileAlt} />
              </div>
              <div className="media-content">
                <p>+6012 520 9862</p>
              </div>
            </div>
            <div className="media-display">
              <div className="media-left">
                <FontAwesomeIcon icon={faPhoneAlt} />
              </div>
              <div className="media-content">
                <p>+603 7971 4567</p>
              </div>
            </div>
            <div className="media-display">
              <div className="media-left">
                <FontAwesomeIcon icon={farEnvelope} />
              </div>
              <div className="media-content">
                <p>elegantklinik@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="column is-one-quarter">
            <h5 className="title is-5">Our Business Hour</h5>
            <div className="media-display">
              <div className="media-left">
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
              <div className="media-content">
                <p>Tuesdays to Sundays</p>
              </div>
            </div>
            <div className="media-display">
              <div className="media-left">
                <FontAwesomeIcon icon={faClock} />
              </div>
              <div className="media-content">
                <p>10:00am to 7:00pm</p>
              </div>
            </div>
            <div className="media-display">
              <div className="media-left">
                <FontAwesomeIcon icon={faCalendarTimes} />
              </div>
              <div className="media-content">
                <p>Closed on</p>
                <p>Mondays & Public Holidays</p>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="columns">
          <div className="column">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe title="Google Map" width="100%" height="450" id="gmap_canvas"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.0343069133664!2d101.69078041461738!3d3.085518797753136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4ba208a73205%3A0xe292d75c1e80ab9f!2sElegant%20Clinic!5e0!3m2!1sen!2ssg!4v1578728224582!5m2!1sen!2ssg" 
                  frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0}></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)
export default ContactUs