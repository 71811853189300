import { Link } from 'react-router-dom'
import DoctorKelvin from '../../assets/images/doctor-kelvin.png'

const OurDoctor = () => (
  <div>
    <div className='anchor' id='our-doctor'></div>
		<section className="section" id="our-doctor-content">
			<div className="container">
        <h3 className="title is-3 has-text-centered title-line">Our Doctor</h3>
        <div className="columns">
          <div className="column is-one-third">
            <figure className="image">
              <img className="is-rounded" src={DoctorKelvin} alt="Doctor Kelvin" />
            </figure>
          </div>
          <div className="column">
            <h5 className="subtitle is-5"><strong>Dr Kelvin Tan Chee Ling (MD)</strong></h5>
            <p className="subtitle is-6">
              Our doctor was raised up in Auckland, New Zealand and completed high school and college is New Zealand. Dr Kelvin obtained his Medical Degree (MBBS) from Kasturba Medical College in 2007.
            </p>
            <p className="subtitle is-6">
              He served with the Government Hospital in Penang for 4 and half years in various disciplines and then he pursued further in Fellowship and Board Certification of Anti-Aging & Regenerative Medicine in Florida, USA and other Aesthetic Medicine Qualifications.
            </p>
            <p className="subtitle is-6">
              <Link className="modal-button" to="/dr-kelvin">{'> Read more...'}</Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
)
export default OurDoctor