import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { request } from '../utils/request'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Service = () => {
  const { id } = useParams() as any
  const [ state, setState ] = useState({ fetching: true })
  const [ service, setService ] = useState<any>({ cover: {} })

  useEffect(() => {
    const fetchArticle = async () => {
      const response = await request.service(id)
      if (response.data && response.data.data && response.data.data.service) {
        if (response.data.data.service.publish) {
          setService(response.data.data.service)
        }
      }
      setState({ ...state, fetching: false })
    }
    fetchArticle()
  }, [])

  const extractService = () => {
    if (state.fetching) {
      return (
        <div className="container content section">
          <p>Loading content...</p>
        </div>
      )
    }

    if (service.id) {
      return (
        <div key={service.id}>
          <div style={{ height: '300px' }}>
            <img style={{ objectFit: 'cover', height: '100%', width: '100%' }} 
              src={process.env.REACT_APP_IMAGE_BASE_URL + service.cover.url} 
              alt={service.title} />
          </div>
          <div className="container content section">
            <h1 className="title">{service.title}</h1>
            <ReactMarkdown 
              className="article"
              source={service.description}
              transformImageUri={uri =>
                uri.startsWith("http") ? uri : `${process.env.REACT_APP_IMAGE_BASE_URL}${uri}`
              }
            />
          </div>
        </div>
      )
    }
    
    return (
      <div className="container content section">
        <h1 className="title">Not Found</h1>
        <p>Sorry, the page you visited does not exists.</p>
      </div>
    )
  }

  return (
    <Layout>
      <SEO title='Service' />
      <div style={{
        marginTop: "5.5rem"
      }}>
        {extractService()}
      </div>
    </Layout>
  )
}

export default Service