import axios, { AxiosResponse } from 'axios'

axios.defaults.baseURL = 'https://manage.elegantclinic.com.my'

const requestHandler: any = async (requestFunc: Promise<AxiosResponse<any>>) => {
  try {
    const response = await requestFunc
    return response
  } catch (error) {
    return error.response
  }
}

export const request = {
  articles: () => requestHandler(axios.post('/graphql', {
    query: `query { articles(limit:500, where: { publish: true }, sort: "updated_at:DESC") { id title subtitle publish cover { url } } }`
  })),
  article: (id: string) => requestHandler(axios.post('/graphql', {
    query: `query { article(id: "${id}") { id title description publish cover { url } } }`
  })),
  services: () => requestHandler(axios.post('/graphql', {
    query: `query { services(limit:500, where: { publish: true }, sort: "updated_at:DESC") { id title subtitle publish cover { url } } }`
  })),
  service: (id: string) => requestHandler(axios.post('/graphql', {
    query: `query { service(id: "${id}") { id title description publish cover { url } } }`
  }))
}