import CompanyLogo from '../../assets/images/company-logo.png'

const SplashScreen = () => (
  <section id="splashscreen">
    <div className="loaders">
      <div className="loaders-beam">
        <img className="loaders-scale" src={CompanyLogo} alt="loading logo" />
      </div>
    </div>
  </section>
)
export default SplashScreen