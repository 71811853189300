import DoctorMedia from '../../assets/media/doctor-media.mp4'

const Media = () => (
  <div>
    <div className='anchor' id='media'></div>
		<section className="section is-medium is-bold" id="media-content">
			<div className="container has-text-centered">
        <h3 className="title is-3 title-line">Media</h3>
        <video controls playsInline>
          <track kind="captions" />
          <source src={DoctorMedia} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  </div>
)
export default Media