import { useEffect, useState } from 'react'
import { request } from '../../utils/request'
import Card from "../../components/card"

const OurServices = () => {
  const [ state, setState ] = useState({ fetching: false })
  const [ services, setServices ] = useState<any[]>([])

  useEffect(() => {
    const fetchService = async () => {
      setState({ ...state, fetching: true })
      const response = await request.services()
      if (response.data && response.data.data && response.data.data.services) {
        setServices(response.data.data.services)
      }
      setState({ ...state, fetching: false })
    }
    fetchService()
  }, [])

  return (
    <div>
      <div className='anchor' id='our-services'></div>
      <section className="section" id="our-services-content">
        <div className="container has-text-centered">
          <h3 className="title is-3 title-line">Our Services</h3>
            <div className="columns is-multiline is-centered">
              { services.map(service => (
                <Card article={service} path="service" key={service.id} />
              )) }
            </div>
        </div>
      </section>
    </div>
  )
}
export default OurServices