import { useEffect, useState } from 'react'
import { request } from '../utils/request'
import Card from '../components/card'
import Layout from '../components/layout'
import SEO from '../components/seo'

const ArticlePage = () => {
  const [ state, setState ] = useState({ fetching: false })
  const [ articles, setArticles ] = useState<any[]>([])

  useEffect(() => {
    const fetchArticle = async () => {
      setState({ ...state, fetching: true })
      const response = await request.articles()
      if (response.data && response.data.data && response.data.data.articles) {
        setArticles(response.data.data.articles)
      }
      setState({ ...state, fetching: false })
    }
    fetchArticle()
  }, [])

  return (
    <Layout>
      <SEO title='Article' />
      <div  style={{
        marginTop: "5.5rem"
      }}>
        <div className="container section">
          <h1 className="title is-3">News & Promotions</h1>
          <div className="columns is-multiline">
            { articles.map(article => (
              <Card article={article} path="article" key={article.id} />
            )) }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ArticlePage