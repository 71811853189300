import { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { request } from '../../utils/request'
import Card from "../../components/card"

const NewsPromotions = () => {
  const [state, setState] = useState({ fetching: false })
  const [articles, setArticles] = useState<any[]>([])

  useEffect(() => {
    const fetchArticle = async () => {
      setState({ ...state, fetching: true })
      const response = await request.articles()
      if (response.data && response.data.data && response.data.data.articles) {
        setArticles(response.data.data.articles)
      }
      setState({ ...state, fetching: false })
    }
    fetchArticle()
  }, [])

  return (
    <div>
      <div className='anchor' id='news-promotions'></div>
      <section className="section" id="news-promotions-content">
        <div className="container has-text-centered">
          <h3 className="title is-3 title-line">News & Promotions</h3>
          <div className="columns is-multiline is-centered">
            {articles.map((article, index) => {
              if (index >= 3) {
                return null
              }
              return <Card article={article} path="article" key={article.id} />
            })}
          </div>
        </div>
        <div className={`navbar-item read-more`}>
          <Link className={`btn-primary`} to="/article"><span>Read more on News & Promotions</span></Link>
        </div>
      </section>
    </div>
  )
}
export default NewsPromotions