import Layout from '../components/layout'
import SEO from '../components/seo'

const DrKelvinPage = () => (
  <Layout>
    <SEO title='Dr Kelvin' />
    <div style={{
      marginTop: "5.5rem"
    }}>
      <div className="container content section">
        <h1 className="title">Dr Kelvin Tan Chee Ling (MD)</h1>
        <p>
          Our doctor was raised up in Auckland, New Zealand and completed high school and college is New Zealand. Dr Kelvin obtained his Medical Degree (MBBS) from Kasturba Medical College in 2007.
        </p>
        <p>
          He served with the Government Hospital in Penang for 4 and half years in various disciplines and then he pursued further in Fellowship and Board Certification of Anti-Aging & Regenerative Medicine in Florida, USA and other Aesthetic Medicine Qualifications.
        </p>
        <p>
          Dr Kelvin is currently the Medical Director of Elegant Clinic and he has been practicing medicine since 2008. He has worked as an Independent Consultant and Aesthetic Physician at Dermatology Centre, Mahkota Medical Centre, a Multidisciplinary Specialist Hospital in the southern city of Melaka. And before that he was engaged in an established Aesthetic Clinic in Johor Bahru and Kuala Lumpur.
        </p>
        <p>
          Qualifications:
          <ul>
            <li>MBBS (Manipal, India)</li>
            <li>Board certified in Aesthetic Medicine (LCP - KKM, Malaysia)</li>
            <li>Fellowshop in Anti-Aging & Regenerative Medicine (Florida, USA)</li>
            <li>Board Certified in Anti-Aging & Regenerative Medicine (Florida, USA)</li>
            <li>Master in PRP & Fat Grafting (USA)</li>
            <li>Master in Hair Transplant & Hair Restoration (Germany)</li>
            <li>Cert. in Hair Transplant & Hair Line Reconstruction (Gangnam, South Korea)</li>
            <li>Diploma in Aesthetic Medicine of AMERICAN ACADEMY OF AESTHETIC MEDICINE (USA)</li>
            <li>Cert. in Aesthetic Medicine of AMERICAN ACADEMY OF AESTHETIC MEDICINE (USA)</li>
            <li>Diploma in Regenera Fibroblast Stem Cell Treatment for Hair Loss & Skin Rejuvenation (Bangkok, Thailand)</li>
          </ul>
        </p>
        <p>
          Other Portfolios:
          <ul>
            <li>Trainer for Autologous Hematopoietic Stem Cells (PRP) in Facial Aesthetic & Hair Loss Treatments</li>
            <li>Visiting Consultant and Aesthetic Physician Mahkota Medical Centre, Melaka</li>
            <li>Member of American Academy of Aesthetic Medicine</li>
            <li>Member of Malaysian Medical Association</li>
          </ul>
        </p>
      </div>
    </div>
  </Layout>
)

export default DrKelvinPage