import { Link } from "react-router-dom"

const Card = ({ article, path }: any) => {
  return (
    <div className="column is-4">
      <Link to={`/${path}/${article.id}`}>
        <div className="card">
          <div className="card-image">
            <figure className="image">
              <div className="cover-image">
                <img style={{ objectFit: 'cover', height: '100%' }} 
                  src={process.env.REACT_APP_IMAGE_BASE_URL + article.cover.url} 
                  alt={article.title} />
              </div>
            </figure>
          </div>
          <div className="card-content">
            <p className="title is-5 is-ellipsis-1">
              {article.title}
            </p>
            <p className="subtitle is-6 is-ellipsis-3">
              {article.subtitle}
            </p>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Card