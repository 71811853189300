import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import ReactGA from 'react-ga'
import './App.css'
import Home from '../pages/home'
import DrKelvin from '../pages/dr-kelvin'
import Article from '../pages/article'
import ArticleTemplate from '../templates/article'
import ServiceTemplate from '../templates/service'
import NotFound from '../pages/404'

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID as string)

const AppRoute = () => {
  const location = useLocation()
  useEffect(() => {
    if (location) {
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [location])

  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/dr-kelvin' component={DrKelvin} />
      <Route exact path='/article' component={Article} />
      <Route exact path='/article/:id' component={ArticleTemplate} />
      <Route exact path='/service/:id' component={ServiceTemplate} />
      <Route component={NotFound} />
    </Switch>
  )
}

const App = () => {
  return (
    <div className="App">
      <Router>
        <AppRoute />
      </Router>
    </div>
  );
}

export default App;
