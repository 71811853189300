import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title='Home' />
    <div style={{
      marginTop: "5.5rem"
    }}>
      <div className="container section">
        <h1 className="title">404 - Not Found</h1>
        <p>You just hit a route that doesn&#39;t exists...</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
