import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fab, faHeart)

const Footer = () => (
  <footer className={`footer`}>
    <div className={`content container has-text-centered social`}>
      <p>Find us on</p>
      <p>
        <a href="https://www.facebook.com/Elegantclinic.com.my/" aria-label='facebook'>
          <FontAwesomeIcon icon={[`fab`, `facebook-square`]} />
        </a>
        <a href="https://www.instagram.com/elegant_clinic/" aria-label='instagram'>
          <FontAwesomeIcon icon={[`fab`, `instagram`]} />
        </a>
      </p>
      <p>© {new Date().getFullYear()}. Elegant Clinic Sdn. Bhd. 
        <FontAwesomeIcon icon={faHeart} className={`heartbeat`} />
      </p>
    </div>
  </footer>
)

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer