const AboutUs = () => (
  <div>
    <div className='anchor' id='about-us'></div>
    <section className="section is-medium is-bold" id="about-us-content">
      <div className="container has-text-centered">
        <h3 className="title is-3 title-line">About Us</h3>
        <p className="subtitle is-6">Elegant Clinic was established in 2018, Our clinic is registered under Ministry of Health. We provide FDA Approved Medical Aesthetic treatments by well recognized and internationally trained doctor. Elegant Clinic Mission was Regain patient’s individual confidence & fulfil their needs is our first priority. Our services via scientifically proven was to give the best option to our patients. We concern about regenerative, aesthetic medicine, health care and wellness. Feel Free to drop by our Elegant Clinic let us combat your problem and regeneration your Elegance.</p>
      </div>
    </section>
  </div>
)
export default AboutUs