import PropTypes from "prop-types"
import { useState } from "react"
import { Link } from "react-router-dom"
import { HashLink } from 'react-router-hash-link'
import CompanyLogo from "../assets/images/company-logo.png"

const Header = () => {
  const [state, setState] = useState({
    isActive: false,
  })

  const toggleActive = () => {
    setState({
      ...state,
      isActive: !state.isActive,
    })
  }

  return (
    <nav className={`navbar is-fixed-top`}>
      <div className={`container`}>
        <div className={`navbar-brand`}>
          <Link className={`navbar-item`} to="/">
            <div>
              <img style={{ marginBottom: 0 }} 
                src={CompanyLogo} 
                alt='company-logo' />
            </div>
          </Link>
          <span className={`navbar-burger burger ${state.isActive ? `is-active` : ``}`} role="button" tabIndex={0} onClick={toggleActive} onKeyPress={() => {}}>
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
      </div>
      <div className={`navbar-menu ${state.isActive ? `is-active` : ``}`}>
        <div className={`navbar-end`}>
          <HashLink className={`navbar-item`} to="/#home">Home</HashLink>
          <HashLink className={`navbar-item`} to="/#about-us">About Us</HashLink>
          <HashLink className={`navbar-item`} to="/#our-services">Our Services</HashLink>
          <HashLink className={`navbar-item`} to="/#our-doctor">Our Doctor</HashLink>
          <Link className={`navbar-item`} to="/article">News & Promotions</Link>
          <div className={`navbar-item`}>
            <HashLink className={`btn-primary`} to="/#contact-us"><span>Contact Us</span></HashLink>
          </div>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
