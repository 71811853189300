import Layout from '../components/layout'
import SEO from '../components/seo'
import AboutUs from './components/about-us'
import Media from './components/media'
import OurServices from './components/our-services'
import NewsPromotions from './components/news-promotions'
import OurDoctor from './components/our-doctor'
import ContactUs from './components/contact-us'
import SplashScreen from './components/splash-screen'
import './styles/index.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faChevronDown)

const HomePage = () => (
  <Layout>
    <SEO title='Home' />
    <SplashScreen/>
    <header className='hero is-fullheight header-image'>
      <div className="header-layer"></div>
			<div className='hero-head'></div>
			<div className='hero-body' id='home'>
				<div className='container has-text-centered'>
          <h1 className='title group-content'>ELEGANT CLINIC</h1>
					<h2 className='subtitle'>Embrace your Beauty, Embrace your Elegance</h2>
				</div>
      </div>
      
      <div className='hero-foot'>
        <div className='container has-text-centered group-content'>
          <a href='/#about-us'><FontAwesomeIcon icon={faChevronDown} id='floating' /></a>
        </div>
      </div>
		</header>
    <AboutUs/>
    <Media/>
    <OurServices/>
    <NewsPromotions/>
    <OurDoctor/>
    <ContactUs/>
  </Layout>
)

export default HomePage
